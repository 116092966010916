body,
html {
  font-family: "Roboto Slab", serif !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main-container {
  padding-top: 20px;
  padding-inline: 12px;
}
.system-navbar {
  height: 50px;
}
.navbar-expand-lg{
  height: 80px;
}
.img-fluid{
  width: 130px;
  height: 160px;
}

.error-field{
  border-color: red !important;
}
.display-none {
  display: none !important;
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.pdding-5 {
  padding: 5px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.w-5 {
  width: 5% !important;
}
.w-6 {
  width: 6% !important;
}
.w-7 {
  width: 7% !important;
}
.w-8 {
  width: 8% !important;
}
.w-9 {
  width: 9% !important;
}
.w-10 {
  width: 10% !important;
}
.w-11 {
  width: 11% !important;
}
.w-14 {
  width: 14% !important;
}
.w-15 {
  width: 15% !important;
}
.w-16 {
  width: 16% !important;
}
.w-17 {
  width: 17% !important;
}
.w-18 {
  width: 18% !important;
}
.w-19 {
  width: 19% !important;
}
.w-20 {
  width: 20% !important;
}
.w-21 {
  width: 21% !important;
}
.w-22 {
  width: 22% !important;
}
.w-23 {
  width: 23% !important;
}
.w-24 {
  width: 24% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-31 {
  width: 31% !important;
}
.w-32 {
  width: 32% !important;
}
.w-33 {
  width: 33% !important;
}
.w-34 {
  width: 34% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-55 {
  width: 55% !important;
}
.w-60 {
  width: 60% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.paragraph-small {
  font-size: 18px;
}
.paragraph-medium {
  font-size: 20px;
}
.text-blue {
  color: #00407a !important;
}
.display-none{
  display: none !important;
}
.display-block{
  display: block !important;
}