.orders-container {
  height: 100%;
}
.order-items {
  height: 100%;
}

.order-table-header {
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  background: rgb(167, 163, 163);
  margin-bottom: 10px;
}

.order-table {
  max-height: 500px;
  overflow-y: AUTO;
  border: 2px solid rgb(167, 163, 163);
}

.order-table-row,
.order-table-header {
  padding-left: 10px;
  padding-right: 10px;
}

.order-table-body .order-table-row:nth-child(odd) {
  background-color: #e2e2e2;
}
.order-table-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.description-cross {
  text-decoration: line-through;
}
.option-dropdown,
.option-input {
  background-color: #b6b4d7 !important;
  border-color: #6c757d !important;
}
.optoin-textbox-remove-btn {
  background-color: #b6b4d7 !important;
}
.order-header h5:first-child {
  white-space: nowrap;
}
.order-info {
  width: 100%;
  border-left: 3px solid rgba(61, 90, 127, 0.839);
  background: #fff;
  padding: 16px;
}
.order-info p {
  color: #575757;
}
.note-list {
  max-height: 185px;
  overflow: auto;
  background: #fff;
}
.note-list ul {
  list-style: none;
  padding-left: 16px;
}
.note-list ul li:not(:last-child) {
  padding-bottom: 16px;
}
.scrollbar::-webkit-scrollbar {
  width: 12px;
}
.scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #90a4ae #cfd8dc;
}
.scrollbar::-webkit-scrollbar-track {
  background: #cfd8dc;
  border-radius: 5px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 5px;
  border: 2px solid #cfd8dc;
}
.tab {
  border-bottom: 1px solid rgb(176 176 176);
}
.tab button {
  outline: none;
  border: none;
  background-color: #f1f1f1;
}
.tab button:is(:active, .active) {
  background-color: #3d5a80;
  color: white;
  border-radius: 5px 5px 0 0;
}
.table-scrollbar::-webkit-scrollbar {
  width: 12px;
}
.table-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #90a4ae #cfd8dc;
}
.table-scrollbar::-webkit-scrollbar-track {
  background: #cfd8dc;
}
.table-scrollbar::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 5px;
  border: 2px solid #cfd8dc;
}
.table-btn {
  background-color: #00509d !important;
  color: white !important;
}
.table-btn:hover {
  background-color: #014a8f !important;
}
input:focus,
select:focus {
  box-shadow: none !important;
}
