.App {
  background: #ebebebb9;
}
#main-navbar {
  padding: 12px 24px !important;
  background: #e6e6e6;
  box-shadow: rgba(0, 0, 0, 0.15) 0.95px 1.95px 1.6px;
}
#main-navbar .navbar-brand {
  font-weight: 500;
}
#main-navbar .navbar-collapse {
  flex-grow: 0;
}
#main-navbar .navbar-nav {
  gap: 32px;
}
#main-navbar #main-navbar-link {
  position: relative;
  line-height: 1.2;
}
#main-navbar #main-navbar-link.active {
  font-weight: 500;
}
#main-navbar #main-navbar-item {
  font-size: 18px;
}
#main-navbar li a#main-navbar-link {
  padding: 0 !important;
}

#main-navbar #main-navbar-item .btn {
  font-size: 16px;
}
