/* .property-container{
    height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
} */

.prop-collapse{
    display: none;
}

.prop-expand{
    display: block;
}
.prop-collapse-icon{
    font-size: 20px !important;
}
.cots-description{
    min-height: 40px;
    background-color: var(--bs-secondary-bg);
    opacity: 1;
	padding: 0.375rem 0.75rem;
	border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}
/* .spec-input{
    height: 40px;
    resize: none;
    overflow: hidden;
} */